<template>
    <div class="google-map" ref="googleMap"></div>
</template>

<script>
// import GoogleMapsApiLoader from "google-maps-api-loader";
// import moment from "moment";
//import vconsole from 'vconsole'
//new vconsole()

export default {
    components: {
        //GoogleMapLoader,
    },
    props: ['filterRegionObj', 'filterCategory'],
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            userMarker: null,
            google: null,
            map: null,
            apiKey: process.env.VUE_APP_GOOGLE_PLACES_API_KEY,
            markers: [],
            stores: [],
            regions: []
        };
    },
    async mounted() {
        this.drawMap();
    },
    computed: {
        mapConfig() {
            return {
                center: { lat: 24.9451338, lng: 121.2685027 },
                zoom: 16,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            };
        },
        mapCenter() {
            return this.markers[1].position;
        }
    },
    methods: {
        mapQuery(query="") {
            var map = this.map;
            // var pyrmont = new google.maps.LatLng(-33.8665433,151.1956316);
            // var center = new google.maps.LatLng(25.0623187,121.1775918);
            

            var request = {
                // location: center,
                // radius: '500',
                query: query
            };
            console.log('the map query is ', query);

            let service = new google.maps.places.PlacesService(map);
            service.textSearch(request, callback);

            let that = this;
            function callback(results, status) {
                
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        that.setMapCenter(JSON.parse(JSON.stringify(place.geometry.location)))
                    }
                }
            }
        },
        drawMap() {
            var that = this;
            this.initializeMap();

            this.callGetStoresAPI()
            // this.dummyStoresResponse()
            .then((response) => {
                console.log("response: ", response);
                response.data.forEach((entry) => {
                    if (entry.info === null) {
                        entry.info = "未提供";
                    }
                    if (entry.geometry) {
                        entry.geometry = JSON.parse(entry.geometry);
                    }
                    if (entry.addressComponents) {
                        entry.addressComponents = JSON.parse(entry.addressComponents);
                    }
                    var marker = {
                        id: entry.id,
                        position: entry.geometry ? entry.geometry.location : {},
                        label: `<br>店家名稱:${entry.name}</br>
                            <br>店家地址:${entry.address}</br>
                            <br>店家電話:${entry.phone}</br>
                            <br>營業資訊:${entry.info}</br>`,
                        storeInfo: {
                            id: entry.id,
                            name: entry.name,
                            address: entry.address,
                            phone: entry.phone,
                            info: entry.info,
                            category: entry.categoryName,
                            isQcEnabled: entry.isQcEnabled,
                            isDdpayEnabled: entry.isDdpayEnabled,
                            image: entry.images ? entry.images[0] : null
                        },
                        iconUrl: entry.gmapIconUrl,
                    };
                    console.log("marker: ",  entry.gmapIconUrl);
                    that.$data.markers.push(marker);
                });
                this.stores = JSON.parse(JSON.stringify(response.data));
                // this.generateRegions();
                // this.getZones();
                this.initializeMarker();
            });
        },
        reDrawMap(zoneObj, categoryId) {
            this.initializeMap();
            if (!zoneObj)
                zoneObj = {};
                
            this.callGetStoresAPI(zoneObj.id, categoryId)
            // this.dummyStoresResponse()
            .then((response) => {
                console.log("response: ", response);
                response.data.forEach((entry) => {
                    if (entry.info === null) {
                        entry.info = "未提供";
                    }
                    if (entry.geometry) {
                        entry.geometry = JSON.parse(entry.geometry);
                    }
                    if (entry.addressComponents) {
                        entry.addressComponents = JSON.parse(entry.addressComponents);
                    }
                    
                });
                this.stores = JSON.parse(JSON.stringify(response.data));
                // this.stores = this.stores.filter(store => {
                //     let addressComponents = store.addressComponents;
                //     if(addressComponents) {
                //         if(this.filterRegion == -1) return true;
                //         return this.regions[this.filterRegion] == addressComponents[addressComponents.length - 4].long_name;
                //     } else {
                //         if(this.filterRegion == -1) return true;
                //         return false;
                //     }
                // });

                // if(this.filterRegion != -1) this.mapQuery(this.regions[this.filterRegion]);
                if(this.filterRegionObj) this.mapQuery(zoneObj.name);                
                this.$data.markers = [];
                this.stores.forEach(entry => {
                    var marker = {
                        id: entry.id,
                        position: entry.geometry ? entry.geometry.location : {},
                        label: `<br>店家名稱:${entry.name}</br>
                            <br>店家地址:${entry.address}</br>
                            <br>店家電話:${entry.phone}</br>
                            <br>營業資訊:${entry.info}</br>`,
                        storeInfo: {
                            id: entry.id,
                            name: entry.name,
                            address: entry.address,
                            phone: entry.phone,
                            info: entry.info,
                            category: entry.categoryName,
                            isQcEnabled: entry.isQcEnabled,
                            isDdpayEnabled: entry.isDdpayEnabled
                        },
                        iconUrl: entry.gmapIconUrl,
                    };
                    this.$data.markers.push(marker);
                })
                this.initializeMarker();
            });
        },
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new google.maps.Map(mapContainer, this.mapConfig);
            this.map.addListener("click", () => {
                this.$emit("map-click", true);
            });
        },

        setMapCenter(pos) {
            this.map.setCenter({
                lat: pos.lat,
                lng: pos.lng
            });
        },

        requestPlaceDetail(placeId) {
            const service = new google.maps.places.PlacesService(
                this.map
            );
            const request = {
                placeId: placeId,
                fields: ["name", "place_id", "geometry", "address_component"]
            };
            service.getDetails(request, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place &&
                    place.geometry &&
                    place.geometry.location
                ) {
                    // const marker = new google.maps.Marker({
                    //     map,
                    //     position: place.geometry.location
                    // });

                    // google.maps.event.addListener(marker, "click", () => {
                    //     const content = document.createElement("div");
                    //     const nameElement = document.createElement("h2");

                    //     nameElement.textContent = place.name;
                    //     content.appendChild(nameElement);

                    //     const placeIdElement = document.createElement("p");

                    //     placeIdElement.textContent = place.place_id;
                    //     content.appendChild(placeIdElement);

                    //     const placeAddressElement = document.createElement("p");

                    //     placeAddressElement.textContent =
                    //         place.formatted_address;
                    //     content.appendChild(placeAddressElement);
                    //     infowindow.setContent(content);
                    //     infowindow.open(map, marker);
                    // });
                }
            });
        },

        initializeUserMarker(pos) {
            const { Marker } = google.maps;

            if (this.userMarker) {
                this.userMarker.setMap(null);
            }
            this.userMarker = new Marker({
                position: pos,
                map: this.map
            });
            this.setMapCenter(pos);
        },

        initializeMarker() {
            const { Marker } = google.maps;
            let googleMarkers = [];
            // const { Marker, InfoWindow } = google.maps;
            // function restoreIcon() {
               
            //     for (var i=0; i<this.markers.length; i++) {
            //        this.markers[i].setIcon('https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png');
            //     }
            // }
            // var activeInfoWindow = null;
            this.markers.forEach((marker) => {
                var localMarker = new Marker({
                    position: marker.position,
                    marker: marker,
                    //label: marker.label,
                    map: this.map,

                    icon: {
                        url: marker.iconUrl,
                        // url: require(`@/assets/img/pin.svg`),
                        // url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
                        // scaledSize: new google.maps.Size(30, 40), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0) // anchor
                    }
                });

                var that = this;

                localMarker.addListener("click", () => {
                    // restore icon for other markers
                    for (var i=0; i<googleMarkers.length; i++) {
                        if (googleMarkers[i] !== localMarker) {                        
                            googleMarkers[i].setIcon(
                                {
                                    url: googleMarkers[i].marker.iconUrl,                                    
                                    // url: require(`@/assets/img/pin.svg`),
                                    // url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
                                    // scaledSize: new google.maps.Size(30, 40), // scaled size
                                    origin: new google.maps.Point(0, 0), // origin
                                    anchor: new google.maps.Point(0, 0) // anchor
                                }
                            );
                        }
                    }
                    // change selected marker icon
                    localMarker.setIcon(
                        {
                            // url: require(`@/assets/img/pin.png`),
                            url: require(`@/assets/img/pin-on-c1.svg`),
                            // scaledSize: new google.maps.Size(30, 40), // scaled size
                            origin: new google.maps.Point(0, 0), // origin
                            anchor: new google.maps.Point(0, 0), // anchor
                        }
                    );
                    that.$emit("store-click", marker.storeInfo);
                    // if (activeInfoWindow !== null) {
                    //     activeInfoWindow.close();
                    //     activeInfoWindow = null;
                    // }
                    // var localInfoWindow = new InfoWindow({
                    //     content: `${marker.label}`
                    // });
                    // localInfoWindow.open(that.map, localMarker);
                    // activeInfoWindow = localInfoWindow;
                });
                googleMarkers.push(localMarker);
            });
        },
        dummyStoresResponse() {
            let response = [
                {
                    "name": "測試商店",
                    "address": "測試地址",
                    "phone": "0987654321",
                    "banId": "85111267",
                    "storePlaceId": "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0109536,
                            "lng": 121.2173832
                        },
                        "viewport": {
                            "south": 25.0100204697085,
                            "west": 121.2164427697085,
                            "north": 25.0127184302915,
                            "east": 121.2191407302915
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大園區",
                            "short_name": "大園區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                },
                {
                    "name": "qwe",
                    "address": "asdf",
                    "phone": "321564897",
                    "banId": "85111246",
                    "storePlaceId": "ChIJ1RXSYsCfQjQRCbG1qZC2o3A",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0796514,
                            "lng": 121.234217
                        },
                        "viewport": {
                            "south": 25.05372919999999,
                            "west": 121.2032256,
                            "north": 25.10275000000001,
                            "east": 121.2624304
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大2區",
                            "short_name": "大2區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                }
            ];
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: response});
                }, 100);
            });
            return promise;
        },
        callGetStoresAPI(zoneId = -1, categoryId = 0) {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/portals/tyapp/stores`,
                method: "get"
            };
            
            let params = {};
            if (zoneId != '-1') params['zone_id'] = zoneId;
            
            if (categoryId != '0') params['category_id'] = categoryId;
            
            if (Object.keys(params).length) config.params = params;

            return this.$http(config)
        },
    },
    watch: {
        filterRegionObj(val) {
            this.reDrawMap(val, this.filterCategory);
        },
        filterCategory(val) {
            this.reDrawMap(this.filterRegionObj, val);
        }
    }
};
</script>
